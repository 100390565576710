@use "@rds/angular-components/styles/angular-components" as rds;
@import "~normalize.css";

@include rds.rds-angular-components();

body {
    min-height: 100vh;
}

.display-block {
    display: block;
}

.full-height {
    height: 100%;
}
